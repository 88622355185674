module.exports = {
  siteTitle: 'Katy Solovewicz Portfolio', // <title>
  manifestName: 'Prologue',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  authorName: 'Katy Solovewicz',
  heading: 'Software Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/kwicz',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIN',
      url: 'https://www.linkedin.com/in/kwicz/',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:katy@solovewicz.com',
    },
  ],
};
